let conf = {

  
    //APIDATA : 'https://data.testflowone.io',
    APIDATA: 'https://data.flowone.io',
    //APIDATA: 'http://localhost:3013'
    
    
  }
  export default conf;
